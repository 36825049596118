import disableScroll from 'disable-scroll';
import { useEffect } from 'react';

export const useDisableScroll = (isMobile: boolean, scrollActive: boolean): void => {
  useEffect(() => {
    if (isMobile || scrollActive) {
      disableScroll.off();
    } else {
      disableScroll.on();
    }

    return () => {
      disableScroll.off();
    };
  }, [isMobile]);
};
