import { GetManyEmployeesQueryPrivate, GetManyRolesQueryPrivate } from '@tests/types';
import { Button, Col, Form, Grid, Row } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'ramda';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { DateFilter, DrawerWrapper, ProjectsFilter, SelectRender } from '@/components';
import { useWindowDimensions } from '@/hooks';
import { rolesEntitySelector } from '@/selectors';
import { STATUS_OPTIONS } from '@/utils';

import styles from './styles.module.scss';

type FiltersProps = {
  fetchRolesData: () => Promise<void>;
  hasMoreRoles: boolean;
  isEmployeesFetching: boolean;
  isRolesFetching: boolean;
  onShowFilters: () => void;
  updateFilters: (params: Partial<GetManyEmployeesQueryPrivate>) => void;
};

export const EmployeeFilters: FC<FiltersProps> = ({
  fetchRolesData,
  hasMoreRoles,
  isEmployeesFetching,
  isRolesFetching,
  onShowFilters,
  updateFilters,
}) => {
  const [checkedProjects, setCheckedProjects] = useState([]);
  const [selectedUpdatedDate, setSelectedUpdatedDate] = useState(null);
  const [selectedCreatedAtData, setSelectedCreatedAtData] = useState(null);
  const [status, setStatus] = useState(null);
  const [role, setRole] = useState(null);
  const [form] = Form.useForm();

  const windowWidth = useWindowDimensions().width;
  const mobileDimension = 768;
  const [isMobile, setIsMobile] = useState(windowWidth < mobileDimension);

  useEffect(() => {
    setIsMobile(windowWidth < mobileDimension);
  }, [windowWidth]);

  const getListQueryParams = useMemo(() => {
    const params = {} as Partial<GetManyEmployeesQueryPrivate & GetManyRolesQueryPrivate>;
    if (selectedCreatedAtData) {
      params.createdAt = selectedCreatedAtData;
    }
    if (selectedUpdatedDate) {
      params.updatedAt = selectedUpdatedDate;
    }
    if (checkedProjects.length) {
      params.projects = checkedProjects.map(({ value }) => value);
    }
    if (status) {
      params.status = status;
    }
    if (role) {
      params.roleId = role;
    }

    return params;
  }, [selectedCreatedAtData, checkedProjects, selectedUpdatedDate, status, role]);

  const handleStatusChange = (value: string) => {
    setStatus(value);
  };

  const handleRoleChange = (role: number) => {
    setRole(role);
  };

  const roles = useSelector(rolesEntitySelector.selectAll) || [];

  const onFinish = useCallback(() => {
    updateFilters(getListQueryParams);
  }, [updateFilters, getListQueryParams]);

  const handleReset = useCallback(() => {
    setCheckedProjects([]);
    setSelectedCreatedAtData(null);
    setSelectedUpdatedDate(null);
    setStatus(null);
    setRole(null);
    form.resetFields();
    updateFilters({});
  }, [updateFilters, form]);

  useEffect(() => {
    if (isEmpty(getListQueryParams)) {
      updateFilters({});
    }
  }, [getListQueryParams, updateFilters]);

  const roleOptions = roles.map(({ id, name }) => ({ label: name, value: id }));

  const formFilters = () => (
    <Form form={form} onFinish={onFinish} onReset={handleReset}>
      <Row className={styles.filters}>
        <Row className={styles.selectors} gutter={[20, 20]}>
          <Col className={styles.selectorWrapper}>
            <Row>
              <Col xs={24}>
                <div className={styles.selectorLabel}>Период создания</div>
                <DateFilter
                  selectedDate={selectedCreatedAtData}
                  setSelectedDate={setSelectedCreatedAtData}
                  name="createdAt"
                  isModal={isMobile}
                />
              </Col>
            </Row>
          </Col>
          <>
            <Col className={styles.selectorWrapper}>
              <Row>
                <Col xs={24}>
                  <div className={styles.selectorLabel}>Период изменения</div>
                  <DateFilter
                    selectedDate={selectedUpdatedDate}
                    setSelectedDate={setSelectedUpdatedDate}
                    name="updatedAt"
                    isModal={isMobile}
                  />
                </Col>
              </Row>
            </Col>
            <Col className={styles.selectorWrapper}>
              <Row>
                <Col xs={24}>
                  <div className={styles.selectorLabel}>Статус</div>
                  <SelectRender
                    value={status}
                    onChange={handleStatusChange}
                    options={STATUS_OPTIONS}
                    isModal={isMobile}
                    classname="employeeFilters"
                  />
                </Col>
              </Row>
            </Col>
            <Col className={styles.selectorWrapper}>
              <Row>
                <Col xs={24}>
                  <div className={styles.selectorLabel}>Проект</div>
                  <ProjectsFilter
                    checkedProjects={checkedProjects}
                    setCheckedProjects={setCheckedProjects}
                    isModal={isMobile}
                    classname="employeeFilters"
                  />
                </Col>
              </Row>
            </Col>
            <Col className={styles.selectorWrapper}>
              <Row>
                <Col xs={24}>
                  <div className={styles.selectorLabel}>Роль</div>
                  <SelectRender
                    value={role}
                    hasMore={hasMoreRoles}
                    fetchData={fetchRolesData}
                    loading={isRolesFetching}
                    options={roleOptions}
                    onChange={handleRoleChange}
                    isModal={isMobile}
                    classname="employeeFilters"
                  />
                </Col>
              </Row>
            </Col>
          </>
        </Row>
        <Row className={styles.filtersBtnWrapper}>
          <Col xs={isMobile && 11}>
            <Button
              className={classNames(styles.filtersBtn, styles.resetFilters)}
              htmlType="reset"
              disabled={isEmpty(getListQueryParams)}
            >
              Сбросить
            </Button>
          </Col>
          <Col xs={isMobile && 11}>
            <Form.Item>
              <Button
                disabled={isEmployeesFetching || isEmpty(getListQueryParams)}
                htmlType="submit"
                className={classNames(styles.filtersBtn, styles.filtersSubmitBtn)}
                type="primary"
              >
                Применить
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Row>
    </Form>
  );

  const modalFilters = () => (
    <DrawerWrapper
      height="auto"
      title="Все фильтры"
      open
      handleClose={onShowFilters}
      mobileDimension={mobileDimension}
    >
      {formFilters()}
    </DrawerWrapper>
  );

  return isMobile ? modalFilters() : formFilters();
};
