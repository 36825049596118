import { Drawer, Modal } from 'antd';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

import { useDisableScroll, useWindowDimensions } from '@/hooks';

import { CloseIcon } from '../../assets';
import styles from './styles.module.scss';

interface Props {
  desktopModal?: boolean;
  handleClose: () => void;
  height?: string | number;
  mobileDimension?: number;
  modalClassName?: string;
  open: boolean;
  scrollActive?: boolean;
  title: string;
}

export const DrawerWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  desktopModal,
  handleClose,
  mobileDimension,
  modalClassName,
  open,
  scrollActive,
  title,
  ...props
}) => {
  const windowDimensions = useWindowDimensions();
  const windowWidth = windowDimensions.width;
  const windowHeight = windowDimensions.height;
  const [isMobile, setIsMobile] = useState(windowWidth < mobileDimension);

  useEffect(() => {
    setIsMobile(windowWidth < mobileDimension);
  }, [windowWidth]);

  useDisableScroll(isMobile, scrollActive);

  if (!isMobile) {
    if (desktopModal) {
      return (
        <Modal
          title={<span className={styles.modalTitle}>{title}</span>}
          open
          onClose={handleClose}
          onCancel={handleClose}
          width="auto"
          footer={null}
          destroyOnClose
          className={modalClassName}
        >
          {children}
        </Modal>
      );
    }

    return <>{children}</>;
  }

  return (
    <Drawer
      title={null}
      closable={false}
      placement="bottom"
      onClose={handleClose}
      open={open}
      className={styles.drawer}
      height={windowHeight * 0.8}
      rootClassName={styles.root}
      {...props}
    >
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div className={styles.close} onClick={handleClose}>
          <CloseIcon className={styles.closeIcon} />
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </Drawer>
  );
};
