import { FileResponsePrivate } from '@tests/types';
import { Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { UploadFile } from 'antd/lib';
import classNames from 'classnames';
import { always, cond, equals, multiply, T } from 'ramda';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Trash } from '@/assets';
import { DropType, DropZoneType, SECOND } from '@/constants';
import { ConfigContext } from '@/providers';
import {
  getAcceptString,
  getAcceptStringPlaceholder,
  getMaxSize,
  isComplete,
  isConverting,
  isInProgress,
} from '@/utils';

import {
  useCreateUploadFileMutation,
  useGetFileByIdMutation,
  useUploadFileMutation,
} from '../../services';
import { ButtonSize, ButtonType } from '../../types';
import { getArrayBuffer, isSuccessResult } from '../../utils';
import { Button } from '../Button';
import { DropZoneFilePreview } from '../DropZoneFilePreview';
import { DropZonePreviewRegular } from '../DropZonePreview';
import { DropZonePreviewCompact } from '../DropZonePreview/DropZonePreviewCompact';
import { message } from '../Message';
import styles from './styles.module.scss';

const { Dragger } = Upload;

interface Props {
  backgroundSize?: string;
  className?: string;
  dropType: DropType;
  onChange?: (file?: FileResponsePrivate) => void;
  placeholder?: string;
  rootClassName?: string;
  type?: DropZoneType;
  validErrorMessage?: string;
  value?: FileResponsePrivate;
}

const getDropZonePreviewComponent = cond([
  [equals(DropZoneType.Compact), always(DropZonePreviewCompact)],
  [T, always(DropZonePreviewRegular)],
]);

export const DropZone = ({
  onChange,
  type = DropZoneType.Regular,
  value,
  dropType,
  rootClassName,
  validErrorMessage,
  className,
  placeholder = 'Добавьте изображение',
  backgroundSize,
}: Props) => {
  const {
    upload: { maxSizeImage, timeFileCheck },
  } = useContext(ConfigContext);
  const defaultCheckStatus = multiply(timeFileCheck, SECOND);

  const [createUploadFile] = useCreateUploadFileMutation();
  const [uploadFile] = useUploadFileMutation();
  const [getFileById] = useGetFileByIdMutation();

  const maxSizeFile = getMaxSize(dropType) * maxSizeImage;
  const accept = useMemo(() => getAcceptString(dropType), [dropType]);
  const acceptPlaceholder = useMemo(() => getAcceptStringPlaceholder(dropType), [dropType]);

  const [file, setFile] = useState(value);
  const [progress, setProgress] = useState(0);
  const [uploadState, setUploadState] = useState<UploadFileStatus | 'converting' | null>(null);

  const DropZonePreview = getDropZonePreviewComponent(type);

  const handleDelete = () => {
    onChange(null);
    setFile(null);
  };

  useEffect(() => {
    setFile(value);
  }, [value]);

  const handleChange: (info: UploadChangeParam<UploadFile<FileResponsePrivate>>) => void = (
    info,
  ) => {
    const { response, status } = info.file;
    setUploadState(status);
    if (status === 'done') {
      onChange(response);
      setFile(response);
      setUploadState('converting');
    }
  };

  const uploadImage = async (options: any) => {
    const { file, onError, onProgress, onSuccess } = options;
    try {
      const { size: contentLength, type: contentType } = file;

      if (contentLength > maxSizeFile) {
        throw new Error('Размер файла превышает допустимый размер');
      }

      const createUploadResult = await createUploadFile({
        contentLength,
        contentType,
      });

      if (!isSuccessResult(createUploadResult)) {
        throw createUploadResult.error;
      }

      const {
        data: { fileId, uploadUrl },
      } = createUploadResult;

      const data = await getArrayBuffer(file);

      const onUploadProgress = (event: ProgressEvent) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      };

      const uploadResult = await uploadFile({
        contentType,
        data,
        onUploadProgress,
        url: uploadUrl,
      });

      if (!isSuccessResult(uploadResult)) {
        throw uploadResult.error;
      }

      const fileResponse = await getFileById(fileId);

      if (!isSuccessResult(fileResponse)) {
        throw fileResponse.error;
      }
      onSuccess(fileResponse?.data);
    } catch (err) {
      message({
        description: JSON.stringify(err?.message || ''),
        title: `Ошибка загрузки ${err?.code || ''}`,
        type: 'error',
      });
      onError({ err });
      setUploadState(null);
      setProgress(0);
      setFile(null);
    }
  };

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (isInProgress(file) || isConverting(file)) {
      timerId = setTimeout(async function update() {
        const checkedFile = await getFileById(file.id);
        if ('data' in checkedFile) {
          if (isInProgress(checkedFile?.data) || isConverting(checkedFile?.data)) {
            timerId = setTimeout(update, defaultCheckStatus);
            if (isConverting(checkedFile?.data) && checkedFile?.data?.variants.length > 0) {
              setFile(checkedFile?.data?.variants[0]);
              onChange(checkedFile?.data?.variants[0]);
            }
          } else if (isComplete(checkedFile?.data)) {
            setFile(checkedFile?.data);
            onChange(checkedFile?.data);
          }
        }
      }, defaultCheckStatus);
    } else {
      setFile(file);
      setUploadState(null);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [file, file?.status, file?.id]);

  if (file && isComplete(file)) {
    return (
      <div className={rootClassName}>
        <div className={classNames(styles.previewWrapper, className)}>
          <DropZoneFilePreview file={file} backgroundSize={backgroundSize} />
          <Button
            type={ButtonType.Secondary}
            onClick={handleDelete}
            size={ButtonSize.Small}
            square
            className={styles.deleteButton}
            beforeIcon={<Trash />}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={rootClassName}>
      <Dragger
        rootClassName={classNames(
          styles.dropZoneRoot,
          className,
          validErrorMessage && styles.error,
        )}
        customRequest={uploadImage}
        onChange={handleChange}
        withCredentials
        accept={accept}
        showUploadList={false}
      >
        <DropZonePreview
          accept={acceptPlaceholder}
          title={placeholder}
          uploadState={uploadState}
          progress={progress}
        />
      </Dragger>
    </div>
  );
};
