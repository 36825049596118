import { PermissionAction, PermissionResource } from '@tests/types';
import { inc } from 'ramda';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';

import { usePermissions } from '@/hooks';
import { projectsEntitySelector } from '@/selectors';
import { useGetProjectsListQuery } from '@/services';

import { MultiSelect } from '../MultiSelect';

type FiltersItem = {
  label: string;
  value: number;
};

type FiltersProps = {
  checkedProjects: FiltersItem[];
  classname?: string;
  isModal?: boolean;
  setCheckedProjects: Dispatch<SetStateAction<FiltersItem[]>>;
};

export const ProjectsFilter: React.FC<FiltersProps> = ({
  checkedProjects,
  classname,
  isModal,
  setCheckedProjects,
}) => {
  const [page, setPage] = useState(0);
  const { data: projectsData, isFetching } = useGetProjectsListQuery(
    {
      offset: page * 30,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Project, PermissionAction.Read),
    },
  );

  const projects =
    useSelector(projectsEntitySelector.selectAll)?.map((item) => ({
      label: item.name,
      value: item.id,
    })) || [];
  const hasMore = projectsData && projectsData?.count > projects?.length;

  const fetchData = async () => {
    setPage(inc);
  };

  const handleCheckProjects = (values: FiltersItem[]) => {
    setCheckedProjects(values);
  };

  return (
    <MultiSelect
      value={checkedProjects}
      loading={isFetching}
      options={projects}
      hasMore={hasMore}
      fetchData={fetchData}
      onChange={handleCheckProjects}
      classname={classname}
    />
  );
};
