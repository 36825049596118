import { Button, Col, Form, Input, InputNumber, Row } from 'antd';
import React, { FC } from 'react';

import { DrawerWrapper } from '@/components';
import { DropType, DropZoneType } from '@/constants';
import { useGetProjectByIdQuery, useUpdateProjectByIdMutation } from '@/services';

import { DropZone } from '../../atoms';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  projectId: number;
}

export const ProjectModal: FC<Props> = ({ onClose, projectId }) => {
  const { data, isLoading } = useGetProjectByIdQuery(
    { id: projectId, relations: ['logo'] },
    { refetchOnMountOrArgChange: true, skip: !projectId },
  );

  const [updateProject] = useUpdateProjectByIdMutation();

  const [form] = Form.useForm();

  const { getFieldsValue } = form;

  const onFinish = async () => {
    const formValues = getFieldsValue({ filter: (meta) => meta.touched, strict: true });

    await updateProject({ id: projectId, ...formValues });

    onClose();
  };

  return (
    <DrawerWrapper
      height="auto"
      modalClassName={styles.modal}
      title="Редактирование проекта"
      open
      desktopModal
      handleClose={onClose}
      mobileDimension={768}
    >
      <div className={styles.body}>
        <Form
          name="project"
          form={form}
          initialValues={{
            logo: data?.logo,
            logoId: data?.logoId,
          }}
          layout="vertical"
          validateTrigger="onKeyPress"
          onFinish={onFinish}
          className={styles.form}
        >
          <Row gutter={[0, 5]} className={styles.inputWrapper}>
            <Col span={24}>
              <Form.Item
                name="logoId"
                getValueFromEvent={(value) => value?.id ?? null}
                getValueProps={() => ({
                  value: data?.logo,
                })}
              >
                <DropZone
                  rootClassName={styles.dropZoneRoot}
                  className={styles.dropZone}
                  backgroundSize="contain"
                  type={DropZoneType.Compact}
                  dropType={DropType.Image}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[0, 5]} className={styles.inputWrapper}>
            <span>Cчётчик метрики</span>
            <Form.Item name="ymId">
              <InputNumber
                controls={false}
                placeholder={data?.ymId ? data?.ymId.toString() : 'Добавить номер счётчика'}
              />
            </Form.Item>
          </Row>
          <Row gutter={[0, 5]} className={styles.inputWrapper}>
            <span>API токен</span>
            <Form.Item name="ymToken">
              <Input placeholder={data?.ymToken ? data?.ymToken : 'Добавить API токен'} />
            </Form.Item>
          </Row>
          <Row gutter={[10, 0]} className={styles.btnWrapper} justify="space-between">
            <Col>
              <Button className={styles.cancelBtn} onClick={onClose}>
                Отменить
              </Button>
            </Col>
            <Col>
              <Button className={styles.saveBtn} type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </DrawerWrapper>
  );
};
